/**
 * @generated SignedSource<<8e56af6d3f13038fc4f0401113514fc9>>
 * @relayHash 53ebcedfde32e6f696cc7d8a2593a5b4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f205f5060df8334465ed389c690fad308d9c1c3556c874884043528322963b4f

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraProjectListRightPanelState = "CLOSED" | "OPEN" | "%future added value";
export type src_projectsDirectoryTemplatesSidebar_RecommendationsInProjectListQuery$variables = {
  cloudId: string;
  isAnonymous: boolean;
};
export type src_projectsDirectoryTemplatesSidebar_RecommendationsInProjectListQuery$data = {
  readonly jira?: {
    readonly canCreateProject: boolean | null | undefined;
    readonly sidebarExperimentTemplates: {
      readonly totalCount: number | null | undefined;
    } | null | undefined;
    readonly userPreferences: {
      readonly projectListRightPanelState: JiraProjectListRightPanelState | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ui_projectsDirectoryTemplatesSidebar_RecommendationsInProjectList">;
  } | null | undefined;
};
export type src_projectsDirectoryTemplatesSidebar_RecommendationsInProjectListQuery = {
  response: src_projectsDirectoryTemplatesSidebar_RecommendationsInProjectListQuery$data;
  variables: src_projectsDirectoryTemplatesSidebar_RecommendationsInProjectListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isAnonymous"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "kind": "ScalarField",
  "name": "projectListRightPanelState"
},
v4 = [
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "experimentKey",
    "value": "sidebarExperiment"
  }
],
v5 = {
  "alias": "sidebarExperimentTemplates",
  "args": (v4/*: any*/),
  "concreteType": "JiraProjectListViewTemplateConnection",
  "kind": "LinkedField",
  "name": "projectListViewTemplates",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "totalCount"
    }
  ]
},
v6 = {
  "alias": "canCreateProject",
  "args": [
    (v1/*: any*/),
    {
      "kind": "Literal",
      "name": "type",
      "value": "CREATE_PROJECT"
    }
  ],
  "kind": "ScalarField",
  "name": "canPerform"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "src_projectsDirectoryTemplatesSidebar_RecommendationsInProjectListQuery",
    "selections": [
      {
        "condition": "isAnonymous",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": (v2/*: any*/),
                "concreteType": "JiraUserPreferences",
                "kind": "LinkedField",
                "name": "userPreferences",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ]
              },
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "ui_projectsDirectoryTemplatesSidebar_RecommendationsInProjectList"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "src_projectsDirectoryTemplatesSidebar_RecommendationsInProjectListQuery",
    "selections": [
      {
        "condition": "isAnonymous",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": (v2/*: any*/),
                "concreteType": "JiraUserPreferences",
                "kind": "LinkedField",
                "name": "userPreferences",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__id"
                      }
                    ]
                  }
                ]
              },
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "args": (v4/*: any*/),
                "concreteType": "JiraProjectListViewTemplateConnection",
                "kind": "LinkedField",
                "name": "projectListViewTemplates",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProjectListViewTemplateItem",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "key"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "templateType"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "productKey"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "isProductLicensed"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "title"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "shortDescription"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "isPremiumOnly"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "canCreate"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "recommendationSessionId"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "iconUrl"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "iconDarkUrl"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "description"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "previewUrl"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "previewDarkUrl"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "f205f5060df8334465ed389c690fad308d9c1c3556c874884043528322963b4f",
    "metadata": {},
    "name": "src_projectsDirectoryTemplatesSidebar_RecommendationsInProjectListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "092f3e62a507116f9c083a6f0b93970a";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
