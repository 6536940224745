/**
 * @generated SignedSource<<4fd4936ca5f864fb79a486e481337d84>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type icon_projectsDirectoryTemplatesSidebar_CardIcon$data = {
  readonly iconDarkUrl: AGG$URL | null | undefined;
  readonly iconUrl: AGG$URL | null | undefined;
  readonly " $fragmentType": "icon_projectsDirectoryTemplatesSidebar_CardIcon";
};
export type icon_projectsDirectoryTemplatesSidebar_CardIcon$key = {
  readonly " $data"?: icon_projectsDirectoryTemplatesSidebar_CardIcon$data;
  readonly " $fragmentSpreads": FragmentRefs<"icon_projectsDirectoryTemplatesSidebar_CardIcon">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "icon_projectsDirectoryTemplatesSidebar_CardIcon",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "iconUrl"
    },
    {
      "kind": "ScalarField",
      "name": "iconDarkUrl"
    }
  ],
  "type": "JiraProjectListViewTemplateItem"
};

(node as any).hash = "c402fd47c91e565afa2bde202876eaf8";

export default node;
