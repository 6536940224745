import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'projects-directory-templates-sidebar.title',
		description: 'Title for the template recommendations featured in the sidebar',
		defaultMessage: 'Templates',
	},
	subtitle: {
		id: 'projects-directory-templates-sidebar.subtitle',
		description:
			'Subtitle for the template recommendations in the sidebar, in this case it suggests the user to hover over the templates to preview them',
		defaultMessage: 'Preview a template for your next project',
	},
	moreTemplatesLink: {
		id: 'projects-directory-templates-sidebar.more-templates-link',
		description: 'Link to the templates page, where the user can see more templates to choose from',
		defaultMessage: 'More templates',
	},
	closeButton: {
		id: 'projects-directory-templates-sidebar.close-button',
		description: 'Label for the close button in the sidebar',
		defaultMessage: 'Close',
	},
	tryLozenge: {
		id: 'projects-directory-templates-sidebar.try-lozenge',
		description: 'Label for the try lozenge displayed in templates where the product is unlicensed',
		defaultMessage: 'Try',
	},
	premiumLozenge: {
		id: 'projects-directory-templates-sidebar.premium-lozenge',
		description: 'Label for the premium lozenge in the premium templates',
		defaultMessage: 'Premium',
	},
	productKeyBusinessLabelSpork: {
		id: 'projects-directory-templates-sidebar.product-key-business-label-spork',
		description:
			'Label for the business product key in template preview, this is shown under the main preview image whilst the user hovers in the sidebar',
		defaultMessage: 'Get Jira to use this template',
	},
	productKeySoftwareLabelSpork: {
		id: 'projects-directory-templates-sidebar.product-key-software-label-spork',
		description:
			'Label for the software product key in template preview, this is shown under the main preview image whilst the user hovers in the sidebar',
		defaultMessage: 'Get Jira to use this template',
	},
	productKeyServiceDeskLabel: {
		id: 'projects-directory-templates-sidebar.product-key-service-desk-label',
		description:
			'Label for the service desk product key in template preview, this is shown under the main preview image whilst the user hovers in the sidebar',
		defaultMessage: 'Get Jira Service Management to use this template',
	},
	productKeyProductDiscoveryLabel: {
		id: 'projects-directory-templates-sidebar.product-key-product-discovery-label',
		description:
			'Label for the product discovery product key in template preview, this is shown under the main preview image whilst the user hovers in the sidebar',
		defaultMessage: 'Get Jira Product Discovery to use this template',
	},
	premiumTemplateDiscoveryLabelSpork: {
		id: 'projects-directory-templates-sidebar.premium-template-discovery-label-spork',
		description:
			'Label for the premium template in template preview when a user has a standard edition',
		defaultMessage: 'Upgrade to Jira Premium to use this template',
	},
});
