/**
 * @generated SignedSource<<42195c960acd5f7dce05219d654c4a0f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type card_projectsDirectoryTemplatesSidebar_TemplateCard$data = {
  readonly canCreate: boolean;
  readonly isPremiumOnly: boolean;
  readonly isProductLicensed: boolean;
  readonly key: string;
  readonly productKey: string;
  readonly recommendationSessionId: string;
  readonly shortDescription: string;
  readonly templateType: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"icon_projectsDirectoryTemplatesSidebar_CardIcon">;
  readonly " $fragmentType": "card_projectsDirectoryTemplatesSidebar_TemplateCard";
};
export type card_projectsDirectoryTemplatesSidebar_TemplateCard$key = {
  readonly " $data"?: card_projectsDirectoryTemplatesSidebar_TemplateCard$data;
  readonly " $fragmentSpreads": FragmentRefs<"card_projectsDirectoryTemplatesSidebar_TemplateCard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "card_projectsDirectoryTemplatesSidebar_TemplateCard",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "title"
      },
      "action": "THROW",
      "path": "title"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "shortDescription"
      },
      "action": "THROW",
      "path": "shortDescription"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "key"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "isProductLicensed"
      },
      "action": "THROW",
      "path": "isProductLicensed"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "isPremiumOnly"
      },
      "action": "THROW",
      "path": "isPremiumOnly"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "canCreate"
      },
      "action": "THROW",
      "path": "canCreate"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "productKey"
      },
      "action": "THROW",
      "path": "productKey"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "recommendationSessionId"
      },
      "action": "THROW",
      "path": "recommendationSessionId"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "templateType"
      },
      "action": "THROW",
      "path": "templateType"
    },
    {
      "kind": "FragmentSpread",
      "name": "icon_projectsDirectoryTemplatesSidebar_CardIcon"
    }
  ],
  "type": "JiraProjectListViewTemplateItem"
};

(node as any).hash = "92503294b576eedee21223387c1265a7";

export default node;
