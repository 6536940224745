/**
 * @generated SignedSource<<c7489b2f76248bf4f8fd236f165f98f8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type preview_projectsDirectoryTemplatesSidebar_TemplatePreview$data = {
  readonly description: string;
  readonly key: string;
  readonly previewDarkUrl: AGG$URL;
  readonly previewUrl: AGG$URL;
  readonly title: string;
  readonly " $fragmentType": "preview_projectsDirectoryTemplatesSidebar_TemplatePreview";
};
export type preview_projectsDirectoryTemplatesSidebar_TemplatePreview$key = {
  readonly " $data"?: preview_projectsDirectoryTemplatesSidebar_TemplatePreview$data;
  readonly " $fragmentSpreads": FragmentRefs<"preview_projectsDirectoryTemplatesSidebar_TemplatePreview">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "preview_projectsDirectoryTemplatesSidebar_TemplatePreview",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "title"
      },
      "action": "THROW",
      "path": "title"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "description"
      },
      "action": "THROW",
      "path": "description"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "key"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "previewUrl"
      },
      "action": "THROW",
      "path": "previewUrl"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "previewDarkUrl"
      },
      "action": "THROW",
      "path": "previewDarkUrl"
    }
  ],
  "type": "JiraProjectListViewTemplateItem"
};

(node as any).hash = "83f2fe24a77e391a2bf8c4e6b7ad6e3b";

export default node;
